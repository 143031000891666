import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
//import { Container as BlockContainer, VideoProduct, Parallax } from '../components/Blocks'
import { Container as BlockContainer, VideoProduct} from '../components/Blocks'
import { HeroCarousel } from '../components/Sections'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const IndexPage = () => (
  <Layout>
    <SEO title="Partnering" keywords={[`gatsby`, `application`, `react`]} />
    <BlockContainer padding={4} className="mt-3">
      <Container fluid className="p-0">
        <Row>
          <Col md="12">
            <HeroCarousel
              items={[
                {
                  src: getAssetUrl('consumer/One-Stop-Shop-2.jpg'),
                  altText: 'CONTRACT GROWING',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('consumer/Feedmill-Slider-2.jpg'),
                  altText: 'FEEDMILL',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('consumer/KoopLikas-1.jpg'),
                  altText: 'KOOPLIKAS',
                  captionHeader: '',
                  caption: ''
                },
                {
                  src: getAssetUrl('consumer/Layer-Farm-4.jpg'),
                  altText: 'LAYER FARM',
                  captionHeader: '',
                  caption: ''
                }
              ]}
            />
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={5} className="pb-0">
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Be part of SIDC's Growing Community</h1>
              <p>Be part of the leading Agricultural based Cooperative in the Philippines. Join us and be one of the successful members of SIDC.</p>
              <b>Be a SIDC Partner!</b>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={0} center={false}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="text-center">Choose your Partnership</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="4" md="6" className="p-3">
            <Card>
              <CardBody className="p-4">
                <div className="card-container">
                  <h2><b>SIDC Agriculture</b></h2>
                  <div className="text-justify" style={{ minHeight: '150px'}}>
                    <p>SIDC offers opportunities for its customer to help their business grow</p>
                  </div>
                  <AnchorLink className="btn btn-primary mt-3" href="#agriculture">Know more</AnchorLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" className="p-3">
            <Card>
              <CardBody className="p-4">
                <div className="card-container">
                  <h2><b>SIDC Franchising</b></h2>
                  <div className="text-justify" style={{ minHeight: '150px'}}>
                    <p>We are looking for business partners to widen the network of delivering SIDC Products and Services to its members.</p>
                  </div>
                  <AnchorLink className="btn btn-primary mt-3" href="#sidc-franchise">Know more</AnchorLink>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6" className="p-3">
            <Card>
              <CardBody className="p-4">
                <h2><b>SIDC Investments</b></h2>
                <div className="text-justify" style={{ minHeight: '150px'}}>
                  <p>SIDC launched its investment program as a vehicle that aims to minimize risk, maximize returns and expand further its membership base.</p>
                </div>
                <AnchorLink className="btn btn-primary mt-3" href="#sidc-investments">Know more</AnchorLink>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <VideoProduct
      id="agriculture"
      partnerId="partner-agriculture"
      title="Partnering with SIDC Agriculture"
      advantagesTitle="Benefits Partnering SIDC Agriculture"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "Small Farmers Aid"},
        { iconClass:"fa fa-pencil-square-o", name: "Business Knowledge Assistance"},
        { iconClass:"fa fa-pencil-square-o", name: "SIDC Financial Assistance"},
        { iconClass:"fa fa-pencil-square-o", name: "Economic Gain"}
      ]}
      offerTitle="Forms To Download"
      offers={[
        { name: 'Membership Application Form', link: 'https://cdn.sidc.coop/documents/MembershipForm.pdf', iconClass: 'fa fa-check-square-o' }
      ]}
      videoLink="consumer/videos/SIDC-Feeds.mp4"
      photoLinks={[
        'consumer/Partner_agri_01-250.png',
        'consumer/Image 250x250 2.jpg',
        'consumer/Image 250x250 1.jpg',
        'consumer/Partner_agri_04-250.png'
      ]}
      departmentTitle="Partnering SIDC Departments"
      department1={{
        src: 'consumer/KooPinoy-SIDC-Savings-and-Loans.png'
      }}
      department2={{
        src: 'consumer/Merchandising-and-Trading.png'
      }}
      department3={{
        src: 'consumer/Wholesale_Department.png'
      }}
    >
      <h3>Become part of a growing Cooperative</h3>
      <div className="lead">
        <p>SIDC offers opportunities to its members by helping them with their business.</p>
        <p>Choose what’s best for you and let us know.</p>
      </div>
    </VideoProduct>
    <VideoProduct
      id="sidc-franchise"
      partnerId="partner-franchise"
      title="Partnering with SIDC through Franchising"
      advantagesTitle="Benefits Partnering SIDC Franchise"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "Establishing brand name"},
        { iconClass:"fa fa-pencil-square-o", name: "Products & Services Availability"},
        { iconClass:"fa fa-pencil-square-o", name: "Technical Support"},
        { iconClass:"fa fa-pencil-square-o", name: "Advertising and Marketing Assistance"}
      ]}
      offerTitle="Documents"
      offers={[
        { name: 'Intent Letter for Franchising', link: 'https://cdn.sidc.coop/documents/IntentLetterFranchising.pdf', iconClass: 'fa fa-check-square-o' },
        { header: 'Forms to Download' },
        { name: 'Membership Application Form', link: 'https://cdn.sidc.coop/documents/MembershipForm.pdf', iconClass: 'fa fa-check-square-o' }
      ]}
      videoLink="consumer/videos/SIDC-Franchising.mp4"
      photoLinks={[
        'consumer/Partner_franchaise_01-250.png',
        'consumer/Partner_franchaise_02-250.png',
        'consumer/Partner_franchaise_03-250.png',
        'consumer/Partner_franchaise_04-250.png'
      ]}
      departmentTitle="Partnering SIDC Departments"
      department1={{
        src: 'consumer/KooPinoy-SIDC-Savings-and-Loans.png'
      }}
      department2={{
        src: 'consumer/Merchandising-and-Trading.png'
      }}
      department3={{
        src: 'consumer/Wholesale_Department.png'
      }}
    >
      <h3>Become part of a growing Cooperative</h3>
      <div className="lead">
        <p>Now, SIDC is expanding its Financing, Merchandise and Trading services in CALABARZON, MIMAROPA, North Luzon, Bicol, Palawan and Visayas thru SIDC One-Stop-Shop to serve the developing members in these areas. We are also looking for interested entrepreneur members of SIDC in franchising a Coopmart and be a Partner of SIDC in reaching and helping more farmers and be a part of this Billionaire cooperative!.</p>
      </div>
    </VideoProduct>
    <VideoProduct
      id="sidc-investments"
      partnerId="partner-investments"
      title="Partnering with SIDC through Investment"
      advantagesTitle="Benefits of Partnering SIDC Investments"
      advantages={[
        { iconClass:"fa fa-pencil-square-o", name: "Guaranteed Interest"},
        { iconClass:"fa fa-pencil-square-o", name: "Tax Free"},
        { iconClass:"fa fa-pencil-square-o", name: "Easy Payment Mode"}
      ]}
      offerTitle="Documents"
      offers={[
        { name: 'Investment Form', link: '', iconClass: 'fa fa-check-square-o' },
        { header: 'Forms to Download' },
        { name: 'Membership Application Form', link: '', iconClass: 'fa fa-check-square-o' }
      ]}
      videoLink="consumer/videos/Investment.mp4"
      photoLinks={[
        'consumer/Investment.jpg',
        'consumer/Investment-2.jpg'
      ]}
      departmentTitle="Partnering SIDC Departments"
      department1={{
        src: 'consumer/KooPinoy-SIDC-Savings-and-Loans.png'
      }}
      department2={{
        src: 'consumer/Merchandising-and-Trading.png'
      }}
      department3={{
        src: 'consumer/Wholesale_Department.png'
      }}
    >
      <h3 className="">Become part of a growing Cooperative</h3>
      <div className="lead">
        <p>SIDC remains steadfast in developing businesses that reflects efficiency at par with commercial and large agribusiness companies. With its billion pesos gross revenue per annum and assets. SIDC launched its investment program as a vehicle that aims to minimize risk, maximize returns and expand further its membership base.</p>
      </div>
    </VideoProduct>
  </Layout>
)

export default IndexPage
